import React from 'react'
import SEO from '../components/seo'
import Helmet from 'react-helmet'

class NotFoundPage extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <html className="is-footer-offset" />
        </Helmet>
        <SEO title="404: Not found" />
        <div className="not-found">
          <div className="not-found__inner is-size-3 is-size-4-mobile">
            <p>Seite nicht gefunden:</p>
            <p>Weiterleitung zur Startseite</p>
          </div>
        </div>
      </>
    )
  }
  componentDidMount() {
    setTimeout(() => {
      window.location.href = '/'
    }, 3000)
  }
}

export default NotFoundPage
